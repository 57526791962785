import styled from "styled-components";
import { colors } from "../../../components/common/element/elements.js";

export const Styles = styled.div`
    .invoice-page {
        .invoice-area {
            padding: 20px 0;
            .card{
              border:none;
            .card-header{
            border-radius: 20px 20px 0px 0px;
            button{
            width:100%;
            background-color: #1a5b25;
            border-radius: 20px;
                }                   
            }
            
            }
            }
            table.table.table-bordered {
             
                margin-bottom: 0;
                thead.table {
                    tr {
                        th {
                            text-align:center;
                            border: none;
                            padding: 20px 10px;
                            font-size: 15px;
                            color: ${colors.white};
                        }
                    }
                }
                tbody.table  {
                    tr {
                        td {
                            border: none;
                          
                            padding: 25px 10px;
                            color: ${colors.secondary};
                            vertical-align: middle;
                        }
                    }
                }
            }
        
            thead.payments {
                    tr {
                        th {
                            text-align:center;
                            border: none;
                            padding: 20px 10px;
                            font-size: 15px;
                            color: ${colors.black1};
                        }
                    }
                }
               
            }

             tbody.payments {
                    tr {
                        td {
                            border: none;
                          
                            padding: 25px 10px;
                            color: ${colors.black1};
                            vertical-align: middle;
                        }
                    }
                }
                }
            @media (max-width: 991px) {
                margin-bottom: 30px;
            }
        }
    }
`;
