import React, { Component } from 'react';
import { Container, Row, Col, Table } from 'react-bootstrap';
import HeaderTwo from '../../components/HeaderTwo';
import { BreadcrumbBox } from '../../components/common/Breadcrumb';
import FooterTwo from '../../components/FooterTwo';
import { Styles } from './styles/cart.js';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { InfinitySpin } from 'react-loader-spinner'
import swal from 'sweetalert';
class Cart extends Component {

    constructor(props){
        super(props);
        this.state = {
            cart: [],
            fee : 0,
            lumpsum : 0,
            slotbooking : 0,
            reg_fee : 0,
            installments : 0,
            amount : 0,
            installment : 0,
            discountinPercent: 0,
            discount : 0,
            loading :true,
            promoLoading :false,
            promo:'',
            promo_id:null,
            pay:null,
        }

        this.myCart = this.myCart.bind(this);
        this.getAmount = this.getAmount.bind(this);
        this.checkout = this.checkout.bind(this);
        
        this.confiramCheckout = this.confiramCheckout.bind(this);
        
    }
    componentDidMount() {
       
        this.myCart();
    }
   

    async myCart  () {
        axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${global.config.Token}`;
      
        const cart = await axios.get(global.config.URL.api.apply + 'my-cart');
        if(cart.data.success)
        {
            this.setState({cart: cart.data.cart,loading:false},function(){
                this.getAmount();
            });  

            console.log(cart,'cart')
        }
    }
    async removeFromCart(id,i) {
        axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${global.config.Token}`;
      
        const remove = await axios.get(global.config.URL.api.apply + 'remove-from-cart/'+id);
        let cart = [...this.state.cart]
        cart.splice(i, 1)
        this.setState({ 
            cart: cart,
        },function(){
            this.getAmount();
            
        })
        swal(remove.data.message);
    }
  
   

    getAmount()
    {
        var fee = 0;
        var installments = 0;
        var lumpsum = 0;
        var slotbooking = 0;
        var reg_fee = 0;
        
       for(var i=0; i < this.state.cart.length; i++)
       {
          fee = fee +  this.state.cart[i].course.fee;

          installments  =  installments +  this.state.cart[i].course.installments;
          lumpsum  =  lumpsum +  this.state.cart[i].course.lumpsum;
          slotbooking  =  slotbooking +  this.state.cart[i].course.slot_booking;
          reg_fee  =  reg_fee +  this.state.cart[i].course.reg_fee;
       }
      
       this.setState({ 
        fee: fee,
        installments: installments,
        lumpsum: lumpsum,
        slotbooking:slotbooking,
        reg_fee:reg_fee
    })
    }
    confiramCheckout()
    {
        if(!this.state.pay)
        {
            swal({
                text: "Please Select Payment Mode",
                icon: "error",

              }) 
        }else{
            swal({
                title: "Are you sure?",
                text: `Your invoice amount is Rs:${this.state.pay === 1 ? this.state.lumpsum : this.state.pay === 2 ? this.state.slotbooking : this.state.reg_fee}`,
                icon: "warning",
                buttons: {cancel:"NO",text:"GENERATE INVOICE"},
              })
              .then((yes) => {
                if (yes) {
                 this.checkout()
                } else {
                  
                }
              });
        }
     
    }
    checkout = async() =>{

        if(!global.config.Token){
            this.props.history.push('/login');
        }
        axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${global.config.Token}`;        
        const res = await axios.post(global.config.URL.api.apply + 'onsite-checkout',{
            offer : this.state.promo_id,
            pay : this.state.pay,
          },);
          if(res && res.data.success)
          {
            
              this.props.history.push('onsite-invoices/');
            
           }else{
            swal("Warning",res.data.message, "warning");

            swal({
                title: "Warning",
                text: res.data.message,
                icon: "warning",
                buttons: {cancel:"NO",text:"OK"},
              })
              .then((yes) => {
                if (yes) {
                    this.props.history.push('/onsite-invoices')
                } else {
                    this.props.history.push('/')
                }
              });
          
           }
        

    }
    render() {
        return (
            <Styles>
                {/* Main Wrapper */}
                <div className="main-wrapper cart-page">

                    {/* Header 2 */}
                    <HeaderTwo />

                    {/* Breadcroumb */}
                    <BreadcrumbBox title="My Cart"  bg="Breadcrumbs/extra/Cart.png" />

                    {/* Product Details */}
                    <section className="cart-area">
                      { this.state.loading ? <InfinitySpin  width='200' className="center" color="#4fa94d" /> : 
                        <Container>
                        {
                                 this.state.cart.length > 0 ?
                            <Row>
                           
                                <Col lg="8" md="12">
                              
                                    <div className="product-list table-responsive">
                                        <Table className="table-bordered">
                                            <thead>
                                            <tr>
                                                    <td colSpan={4} className="text-danger">Finally you have selected you course.Now click on GENERATE INVOICE button to get your fee slip.</td>
                                                    
                                                   
                                                </tr>
                                                <tr>
                                                    <th className="product-remove"></th>
                                                    <th className="product-thumbnail"></th>
                                                    <th className="product-name">Couse</th>
                                                    <th className="product-price">Fee</th>
                                                   
                                                </tr>
                                            </thead>
                                           
                                            <tbody>
                                                {
                                                 
                                                   this.state.cart.map((cart, i) => (
                                                    <tr  key={i}>
                                                    <td className="product-remove"><a href="#!" onClick={() => this.removeFromCart(cart.id,i)}><i className="las la-trash"></i></a></td>
                                                    <td className="product-thumbnail"><a href='#!' onClick={() => {window.location.href=`${process.env.PUBLIC_URL + cart.course.slug}`}}><img src={global.config.URL.img.public + cart.course.course_detail.img} alt="" /></a></td>
                                                    <td className="product-title"><a href='#!' onClick={() => {window.location.href=`${process.env.PUBLIC_URL + cart.course.slug}`}}>{cart.course.name}</a></td>
                                                    <td className="product-price"><span className="amount">Rs:<del className='text-danger'>{cart.course.fee.toLocaleString()}</del>{cart.course.lumpsum ?cart.course.lumpsum.toLocaleString():""}</span></td>
                                                   </tr>
                                                   ))
                                                   
                                                }                   
                                            </tbody>
                                        </Table>
                                        <div className="actions d-flex justify-content-between">
                                            <div className="coupon"> 
                                                {/* <form action="#!" className="form-inline" onSubmit={(e) => this.promo(e)}>
                                                    <input type="text" className="form-control" onChange={e => this.setState({promo:e.target.value})} value={this.state.promo} placeholder="Promo code (optional)" required />
                                                   {this.state.promoLoading ? <button disabled className="apply-btn"> <InfinitySpin  width='100' color="#ffb200" /></button> : <button type="submit" className="">Check Promo</button>} 
                                                </form> */}
                                            </div>
                                            <div className="update-cart">
                                                <a className="update-btn" href={process.env.PUBLIC_URL + "/onsite-course-registration"}>Update cart</a>
                                            </div>
                                        </div>
                                        
                                    </div>
                                     
                                </Col>
                                                
                                <Col lg="4" md="6">
                                    <div className="cart-summary">
                                        <div className="cs-title text-center">
                                            <h5>Cart Summary</h5>
                                        </div>
                                        <div className="cs-content">
                                        <p className="cart-total text-large mb-3">Grand Fee <span><del>{this.state.fee.toLocaleString()}</del></span></p>
                                        <p className="cart-total big-text mb-3" >Lump Sum Fee <span>{this.state.lumpsum.toLocaleString()}</span></p>
                                        <p className="cart-total mb-3" >With Installments<span>{this.state.installments.toLocaleString()}</span></p>
                                        <p className="cart-total "><input  type="radio" onClick={() => this.setState({pay:1})} name='pay' className='check-box mr-1'/>Pay Full Fee <span>{this.state.lumpsum.toLocaleString()}</span></p>
                                        <p className="cart-total "><input  type="radio" onClick={() => this.setState({pay:2})} name='pay' className='check-box mr-1'/>Slot Booking <span>{this.state.slotbooking.toLocaleString()}</span></p>
                                        <p className="cart-total"><input  type="radio" onClick={() => this.setState({pay:3})} name='pay' className='check-box mr-1'/>Registration Fee <span>{this.state.reg_fee.toLocaleString()}</span></p>
                                        {/* <ul className="list-unstyled">
                                            <li>Discount ({this.state.discountinPercent}%) <span>{this.state.discount}</span></li>
                                            </ul> */}
                                            <button type="button" onClick={() => this.confiramCheckout()} className="checkout-btn">Generate Invoice</button>
                                        </div>
                                    </div>
                                </Col>
 
                            </Row>
                            :
                             <div className='text-center text-light'>         
                              
                            <h4 className='bg-danger'>  <i className="lab la-bitbucket"></i> Empty Cart</h4>
                           <Link to='/onsite-course-registration'>Register for new courses</Link>
                             </div>
                           }
                        </Container>
                      }
                    </section>

                    {/* Footer 2 */}
                    <FooterTwo />

                </div>
            </Styles>
        )
    }
}

export default Cart